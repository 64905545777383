import { useEffect } from 'react'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import FocusAreaDesktop from '../../components/voyages/focusArea/FocusAreaDesktop'
import FocusAreaMobile from '../../components/voyages/focusArea/FocusAreaMobile'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import secureScreen from '../../hooks/authentication/secureScreen'
import useAnalytics from '../../hooks/useAnalytics'
import DefaultLayout from '../../layouts/DefaultLayout'
import { useFocusArea } from '../../queries/voyageQueries'

const FocusAreaScreen = ({ route }) => {
  const { focusArea } = route.params
  const formattedFocusArea = focusArea.replace(/\s+/g, '_')
  const { data, isLoading } = useFocusArea(formattedFocusArea)
  const analytics = useAnalytics()

  const isDesktop = useDesktopBreakpoint()

  useEffect(() => {
    if (data) {
      analytics.track('Focus Area Viewed', {
        title: data.name,
        url: window.location?.href,
        description: data.description,
      })
    }
  }, [data])

  return (
    <DefaultLayout
      hasNavFooter={false}
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      bg={isDesktop ? 'muted.50' : 'white'}>
      {!isDesktop ? (
        <FocusAreaMobile
          data={data}
          isLoading={isLoading}
          focusArea={formattedFocusArea}
        />
      ) : (
        <FixedContainer size="lg">
          <FocusAreaDesktop
            data={data}
            isLoading={isLoading}
            focusArea={formattedFocusArea}
          />
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default secureScreen(FocusAreaScreen)
