import { Box, Button, Center, Flex, Text } from 'native-base'
import { useMicrophonesContext } from '../../../providers/MicrophoneProvider'
import Caption from '../../_shared/text/Caption'
import TextLink from '../../_shared/text/TextLink'

const JoinRoom = ({ handlePress }) => {
  const { selectedMicrophone } = useMicrophonesContext()
  const hasMicrophone =
    selectedMicrophone?.name !== 'No microphone' && selectedMicrophone

  return (
    <>
      <Flex>
        <Text color="primary.900" fontWeight="bold">
          Don't forget...
        </Text>
        {hasMicrophone ? (
          <>
            <Text color="primary.900">• Your voice is key</Text>
            <Text color="primary.900">• Sharing is encouraged</Text>
            <Text color="primary.900">• Come with an open mind</Text>
            <Text color="primary.900">• Invest in yourself</Text>
          </>
        ) : (
          <>
            <Text color="primary.900">• You’re “Chat Only”</Text>
            <Text color="primary.900">• Sharing is encouraged</Text>
            <Text color="primary.900">• Come with an open mind</Text>
            <Text color="primary.900">
              • Good work–you showed up for yourself today
            </Text>
          </>
        )}
      </Flex>
      <Center pt="84px">
        <Button onPress={handlePress} colorScheme="primary" width="100%" my="3">
          Join Room
        </Button>
        <Box maxWidth="250">
          <Caption textAlign="center">
            By joining, you agree to Cabana by Even Health's{' '}
            <TextLink
              href="https://mycabana.health/terms-of-service"
              fontWeight="bold"
              color="text.500">
              Terms of Service
            </TextLink>{' '}
            and{' '}
            <TextLink
              href="https://www.mycabana.health/privacy-policy"
              color="text.500"
              fontWeight="bold">
              Privacy Policy
            </TextLink>
            .
          </Caption>
        </Box>
      </Center>
    </>
  )
}

export default JoinRoom
