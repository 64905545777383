import { Box, Center, Image, Text } from 'native-base'
import useLoadAnimations from '../../hooks/useLoadAnimations'
import BaseLayout from '../../layouts/BaseLayout'
import { useRoomContext } from '../../providers/Room30Provider'
import Card from '../_shared/card/Card'
import Caption from '../_shared/text/Caption'
import LiveGroupHeading from './LiveGroupHeading'

const ParticipantLobby = () => {
  const { info } = useRoomContext()
  const animation = useLoadAnimations()

  return (
    <BaseLayout>
      <Box mt="2" mb="5">
        <LiveGroupHeading info={info} />
      </Box>
      <Center>
        <Card
          title="Your group will start soon"
          hasCenterTitle
          boxProps={{ h: '478px' }}
          body={
            <Center>
              <Text mb="4" w="220px" textAlign="center">
                While you wait, try practicing this simple breathing exercise
              </Text>
              <Image source={animation} alt="Breathing exercise" size="300" />
              <Box my="4">
                <Caption>Animation by Nathan Pyle</Caption>
              </Box>
            </Center>
          }
        />
      </Center>
    </BaseLayout>
  )
}

export default ParticipantLobby
