import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import {
  Microphone,
  NO_MICROPHONE,
} from '../../../domain/models/interfaces/microphone'
import useMicrophones, { UseMicrophonesResult } from '../hooks/useMicrophones'
import useStorage from '../../../domain/services/useStorage'

export interface UseMicrophonesContext extends UseMicrophonesResult {
  selectMicrophone: (Microphone) => void
  selectedMicrophone: Microphone | null
}

const MicrophonesContext = createContext<UseMicrophonesContext | undefined>(
  undefined
)

export const useMicrophonesContext = (): UseMicrophonesContext =>
  useContext(MicrophonesContext)

interface MicrophonesProviderProps {
  children: ReactNode
}

export const MicrophonesProvider: React.FC<MicrophonesProviderProps> = ({
  children,
}) => {
  const useMicrophonesHook = useMicrophones()
  const [selectedMicrophone, setSelectedMicrophone] =
    useState<Microphone>(NO_MICROPHONE)
  const [savedMicrophone, setSavedMicrophone] = useStorage('@microphone', NO_MICROPHONE)

  const selectMicrophone = (microphone: Microphone) => {
    setSelectedMicrophone(microphone)
    setSavedMicrophone(microphone.name)
  }

  useEffect(() => {
    const matchingMicrophone = useMicrophonesHook.microphones.find(microphone => microphone.name === savedMicrophone)
    setSelectedMicrophone(matchingMicrophone || NO_MICROPHONE)
  }, [useMicrophonesHook.microphones, savedMicrophone])

  const context: UseMicrophonesContext = {
    ...useMicrophonesHook,
    selectMicrophone,
    selectedMicrophone,
  }

  return (
    <MicrophonesContext.Provider value={context}>
      {children}
    </MicrophonesContext.Provider>
  )
}
