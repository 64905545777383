import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, Center, Text } from 'native-base'
import BaseLayout from '../../../layouts/BaseLayout'
import { useRoomContext } from '../../../providers/Room30Provider'
import { NavigationParams } from '../../../screens/NavigationScreen'
import LiveGroupHeading from '../LiveGroupHeading'

const RoomCompleted = () => {
  const { info } = useRoomContext()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()

  const goHome = () => {
    navigation.navigate('Home')
  }

  return (
    <BaseLayout>
      <Box position="absolute" w="100%" mt="2">
        <LiveGroupHeading hasWelcome={false} info={info} />
      </Box>
      <Center my="auto">
        <Text textAlign="center" fontSize="3xl">
          Oops!
          {'\n'}
          This live group has ended
        </Text>
        <Button colorScheme="primary" onPress={goHome} mt="10">
          Return to home
        </Button>
      </Center>
    </BaseLayout>
  )
}

export default RoomCompleted
