import { library } from '@fortawesome/fontawesome-svg-core'
import { StatusBar } from 'native-base'
import { useEffect } from 'react'
import BugSnagService from './src/domain/services/bugsnag/BugSnagService'
import clevertap from './src/domain/services/clevertap/ClevertapAdapter'
import hotjar from './src/domain/services/hotjar/HotjarAdapter'
import useMixpanel from './src/infrastructure/views/hooks/mixpanel/useMixpanel'
import AppProvider from './src/infrastructure/views/providers/_AppProvider'
import NavigationScreen from './src/infrastructure/views/screens/NavigationScreen'
import icons from './src/infrastructure/views/theme/icons'

const App = ({}) => {
  useEffect(() => {
    library.add(icons)
  }, [])

  return (
    <AppProvider>
      <Initialization />
      <StatusBar backgroundColor="#222b60" barStyle="light-content" />
      <NavigationScreen />
    </AppProvider>
  )
}

const Initialization = () => {
  const mixpanel = useMixpanel()

  useEffect(() => {
    clevertap.initialize()
    mixpanel.init()
    BugSnagService.init()
    hotjar.initialize(3038137, 6)
  }, [])

  return <></>
}

export default App
