import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import BottomSheet from '../../_shared/notification/BottomSheet'
import DeviceSettings from '../orientation/DeviceSettings'

const RoomSettings = ({ settingsOpen, closeSettings }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <BottomSheet
      isOpen={settingsOpen}
      width="320px"
      atBottom
      atRight
      title="Settings"
      body={<DeviceSettings />}
      handleClose={closeSettings}
      hasCloseOnBottom={true}
    />
  )
}

export default RoomSettings
