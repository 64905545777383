import { useBreakpointValue } from 'native-base'

import { useEffect } from 'react'
import BrowserRoom from '../../components/liveGroup/room30/BrowserRoom'
import MobileRoom from '../../components/liveGroup/room30/MobileRoom'
import secureScreen from '../../hooks/authentication/secureScreen'
import useIntercom from '../../hooks/useIntercom.web'

const RoomScreen30 = ({ isLobbyRoom }) => {
  const { update } = useIntercom()
  const breakpoint = useBreakpointValue({
    base: 'mobile',
    lg: 'browser',
  })

  useEffect(() => {
    update({ hideDefaultLauncher: true })
  }, [])

  return breakpoint === 'mobile' ? (
    <MobileRoom />
  ) : (
    <BrowserRoom isLobbyRoom={isLobbyRoom} />
  )
}

export default secureScreen(RoomScreen30)
