import { Box, HStack, VStack } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { ImageBackground, StyleSheet } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { AttendeeState30 } from '../../../../../domain/enums/AttendeeState'
import { AudioStream } from '../../../hooks/useMeeting'
import useRoomToasts from '../../../hooks/useRoomToasts'
import useSpeakingDetection from '../../../hooks/useSpeakingDetection'
import { useCamerasContext } from '../../../providers/CameraProvider'
import { useRoomContext } from '../../../providers/Room30Provider'
import VideoFeed from '../VideoFeed'
import Chat from './Chat'
import LiveGroupModal from './LiveGroupModal.web'
import RoomFooter from './RoomFooter'
import Roster from './Roster'
import ScreenShareAlert from './ScreenShareAlert'

const BackgroundImage = require('../../../assets/images/room/background-beach.webp')

const BrowserRoom = ({ isLobbyRoom = false }) => {
  const { me, audio, showChat, showParticipants, participants } =
    useRoomContext()
  const { isSpeaking } = useSpeakingDetection()
  const { requestCameraPermission } = useCamerasContext()

  const [isStartGroupModalOpen, setIsStartGroupModalOpen] = useState(false)
  const [isActionModalOpen, setIsActionModalOpen] = useState(false)

  const audioRefs = useRef<Record<string, AudioStream>>({})

  useEffect(() => {
    if (me.role !== AttendeeRole.Moderator) return

    requestCameraPermission()
  }, [])

  useEffect(() => {
    audio.forEach((stream) => {
      if (audioRefs.current[stream.id]) return

      audioRefs.current[stream.id] = stream

      stream.play()
    })

    return () => {
      Object.values(audioRefs.current).forEach((stream) => stream.stop())
      audioRefs.current = {}
    }
  }, [audio])

  useEffect(() => {
    if (me?.role === AttendeeRole.Moderator) {
      if (isLobbyRoom) {
        setIsStartGroupModalOpen(true)
        setIsActionModalOpen(false)
      } else if (me?.screen === AttendeeState30.Room) {
        setIsStartGroupModalOpen(false)
        setIsActionModalOpen(true)
      }
    }
  }, [isLobbyRoom])

  const footerHeight = 64
  const marginBottom = `${footerHeight + 4}px`

  const currentAudio = {
    enabled: me?.microphone,
    iMutedMyself: !me?.microphone,
  }

  const presenter =
    participants.find((participant) => participant.screenShare)?.alias || ''
  const isScreenSharing = !!presenter

  useRoomToasts({
    isAudioEnabled: currentAudio.enabled,
    iMutedMyself: currentAudio.iMutedMyself,
    isScreenSharing: isScreenSharing,
    personSharing: presenter,
    isSpeaking: isSpeaking,
  })

  return (
    <ImageBackground source={BackgroundImage} style={styles.backgroundImage}>
      <VStack flex={1} p={10}>
        <ScreenShareAlert />
        <HStack
          marginBottom={marginBottom}
          justifyContent="space-between"
          alignItems="center"
          flex={1}>
          {showParticipants ? (
            <Roster />
          ) : (
            <Box width="320px" backgroundColor="transparent" h="100%" />
          )}
          <Box
            alignSelf="flex-start"
            height="340px"
            maxWidth="640px"
            flex={1}
            mx={3}
            borderRadius={6}
            background="black">
            <VideoFeed />
          </Box>
          {showChat ? (
            <Chat />
          ) : (
            <Box width="320px" backgroundColor="transparent" h="100%" />
          )}
        </HStack>
      </VStack>
      <Box
        position="absolute"
        bottom="0"
        h={`${footerHeight}px`}
        w="100%"
        bg="black"
        safeAreaBottom>
        <RoomFooter />
      </Box>
      {me?.role === AttendeeRole.Moderator && (
        <>
          <LiveGroupModal
            groupInactive={me.screen === AttendeeState30.Lobby}
            show={isStartGroupModalOpen}
            body="Lights, camera..."
            heading="Ready to start the group?"
          />
          <LiveGroupModal
            closeModal={() => setIsActionModalOpen(false)}
            show={isActionModalOpen}
            body="Action!"
            heading="You're live!"
          />
        </>
      )}
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
})

export default BrowserRoom
