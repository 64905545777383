import { Button, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { useRoomContext } from '../../../providers/Room30Provider'
import FaIcon from '../../_shared/FaIcon'

const StartRoom = () => {
  const { me, info, start, stop } = useRoomContext()
  const [isActive, setActive] = useState(false)
  const [startButtonEnabled, setStartButtonEnabled] = useState(false)

  useEffect(() => {
    setActive(me.screen === 'ROOM')
    setStartButtonEnabled(me.screen === 'LOBBY')

    let interval = null

    if (me.screen !== 'ROOM') {
      let startThreshold = new Date(info.startTime)
      startThreshold.setMinutes(startThreshold.getMinutes() - 15)

      interval = setInterval(() => {
        let readyToStart = startThreshold < new Date()
        setStartButtonEnabled(readyToStart)
        if (readyToStart) {
          clearInterval(interval)
          interval = null
        }
      }, 3000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [me.screen, info.startTime])

  const toggleIsActive = () => {
    if (!isActive) {
      start()
    } else {
      stop()
    }
  }

  return (
    <>
      <Button
        backgroundColor={
          isActive
            ? 'error.500'
            : startButtonEnabled
            ? 'secondary.500'
            : 'muted.400'
        }
        borderRadius="full"
        leftIcon={
          <FaIcon
            name={isActive ? 'arrow-right-from-bracket' : 'circle-play'}
            size={20}
            color={startButtonEnabled ? 'text.50' : 'text.700'}
          />
        }
        onPress={toggleIsActive}
        disabled={!startButtonEnabled}>
        <Text color={startButtonEnabled ? 'text.50' : 'text.700'}>
          {isActive ? 'Stop' : 'Start'} group
        </Text>
      </Button>

      {!startButtonEnabled && (
        <Text ml="10px" mt="10px" color="primary.900">
          You'll be able to start this group 15 minutes before its scheduled
          start time
        </Text>
      )}
    </>
  )
}

export default StartRoom
