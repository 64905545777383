import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useMemo, useRef, useState } from 'react'
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native'
import CameraPicker from '../components/liveGroup/CameraPicker'
import MicrophonePicker from '../components/liveGroup/MicrophonePicker'
import secureScreen from '../hooks/authentication/secureScreen'
import { AudioStream } from '../hooks/useMeeting'
import useSpeakingDetection from '../hooks/useSpeakingDetection'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { CamerasProvider } from '../providers/CameraProvider'
import { MicrophonesProvider } from '../providers/MicrophoneProvider'
import { RoomProvider, useRoomContext } from '../providers/Room30Provider'
import { useUserProfileContext } from '../providers/UserProfileProvider'

const RoomTestScreen = () => {
  //hardcode the room
  const roomId = 'recpXXZwpy8m2IM0r'

  // auto-start
  const { RoomApiV2 } = useCabanaApi()
  useEffect(() => {
    RoomApiV2.start(roomId)
  }, [])

  // auto-join
  const { userProfile } = useUserProfileContext()
  useEffect(() => {
    if (userProfile.id) RoomApiV2.join(roomId, userProfile.id)
  }, [userProfile.id])

  return (
    <MicrophonesProvider>
      <CamerasProvider>
        <RoomProvider roomId={roomId}>
          <RoomTestComponent />
        </RoomProvider>
      </CamerasProvider>
    </MicrophonesProvider>
  )
}

const RoomTestComponent = () => {
  const { me } = useRoomContext()

  return (
    <HStack flex={1} space={4} padding={4}>
      <VStack space={4}>
        <MicrophoneStuff />
        <CameraStuff />
        <Controls />
        <Participants />
        <Audios />
      </VStack>
      <VStack flex={1} space={4}>
        <Videos />
        <Chat flex={1} />
      </VStack>
    </HStack>
  )
}

const Panel = ({ children, title, ...props }) => {
  return (
    <VStack
      space={2}
      outlineStyle={'solid'}
      borderRadius={'md'}
      padding={4}
      {...props}>
      {title && <Heading>{title}</Heading>}
      {children}
    </VStack>
  )
}

const MicrophoneStuff = () => {
  const { isSpeaking } = useSpeakingDetection()

  return (
    <Panel title="Microphone">
      <MicrophonePicker />
      <Text>
        You are&nbsp;
        {isSpeaking ? (
          <Text fontWeight={'bold'} color={'success.700'}>
            speaking
          </Text>
        ) : (
          <Text>not speaking</Text>
        )}
      </Text>
    </Panel>
  )
}

const CameraStuff = () => {
  return (
    <Panel title="Camera">
      <CameraPicker />
    </Panel>
  )
}

const Controls = () => {
  return (
    <Panel title="Controls">
      <HStack space={4}>
        <MuteButton />
        <CameraButton />
        <HandButton />
        <AliasPicker />
      </HStack>
    </Panel>
  )
}

const ControlButton = ({ icon, color, onPress }) => {
  return (
    <IconButton
      borderRadius={'full'}
      outlineStyle={'solid'}
      icon={<FontAwesomeIcon icon={icon} size={24} color={color || 'black'} />}
      onPress={onPress}
    />
  )
}

const MuteButton = () => {
  const { me, microphoneOff, microphoneOn } = useRoomContext()

  return (
    <ControlButton
      icon={'fa-microphone'}
      color={me?.microphone ? 'green' : 'grey'}
      onPress={() => (me?.microphone ? microphoneOff() : microphoneOn())}
    />
  )
}

const CameraButton = () => {
  const { me, cameraOff, cameraOn } = useRoomContext()

  return (
    <ControlButton
      icon={'fa-regular fa-video'}
      color={me?.camera ? 'green' : 'grey'}
      onPress={() => (me?.camera ? cameraOff() : cameraOn())}
    />
  )
}

const HandButton = () => {
  const { me, handDown, handUp } = useRoomContext()

  return (
    <ControlButton
      icon={'hand'}
      color={me?.handRaised ? 'green' : 'grey'}
      onPress={() => (me?.handRaised ? handDown() : handUp())}
    />
  )
}

const AliasPicker = () => {
  const { me, chooseAlias } = useRoomContext()
  const [alias, setAlias] = useState<string>(me?.alias || '')

  useEffect(() => {
    setAlias(me?.alias || '')
  }, [me?.alias])

  return (
    <InputGroup size={'sm'}>
      <InputLeftAddon children={'Alias'} />
      <Input value={alias} onChangeText={chooseAlias} />
    </InputGroup>
  )
}

const Participants = () => {
  const { participants } = useRoomContext()

  return (
    <Panel title={'Participants'}>
      {participants
        .filter((participant) => participant.connected)
        .map((participant) => (
          <Participant key={participant.id} participant={participant} />
        ))}
    </Panel>
  )
}

const Participant = ({ participant }) => {
  return (
    <HStack space={8}>
      <Text width={'80px'}>
        {participant.alias || participant.id.slice(0, 13) + '...'}
      </Text>
      {participant.microphone && (
        <FontAwesomeIcon icon={'microphone'} color={'black'} size={16} />
      )}
      {participant.camera && (
        <FontAwesomeIcon
          icon={'fa-regular fa-video'}
          color={'black'}
          size={16}
        />
      )}
      {participant.handRaised && <FontAwesomeIcon icon={'hand'} size={16} />}
      {participant.speaking && <FontAwesomeIcon icon={'waveform'} size={16} />}
      {participant.typing && <FontAwesomeIcon icon={'keyboard'} size={16} />}
      {participant.role === 'SPECTATOR' && <Text size={'xs'}>Chat only</Text>}
    </HStack>
  )
}

const Audios = () => {
  const { audio } = useRoomContext()
  const audioRefs = useRef<Record<string, AudioStream>>({})

  // handle audio stream changes
  useEffect(() => {
    audio.forEach((stream) => {
      // skip audio we've already started playing
      if (audioRefs.current[stream.id]) return

      // store the stream by id
      audioRefs.current[stream.id] = stream

      // play the audio stream
      stream.play()
    })

    // clean-up audio
    return () => {
      Object.values(audioRefs.current).forEach((stream) => stream.stop())
    }
  }, [audio])

  return <></>
}

const Videos = ({ ...props }) => {
  const { video } = useRoomContext()
  const container = useRef<HTMLElement>()
  const videoRefs = useRef<Record<string, HTMLVideoElement>>({})

  // handle video stream changes
  useEffect(() => {
    video.forEach((stream) => {
      // skip streams we've already started playing
      if (videoRefs.current[stream.id]) return

      // create the <video> in the container and store it with the stream id
      const el: HTMLVideoElement = document.createElement('video')
      el.autoplay = true
      el.playsInline = true
      el.style.width = '30%'
      container.current.appendChild(el)
      videoRefs.current[stream.id] = el

      // attach the stream
      stream.attach(el)
    })

    // clean-up video
    return () => {
      Object.values(videoRefs.current).forEach((el) => el.remove())
    }
  }, [video])

  return (
    <Panel title={'Video'}>
      <HStack ref={container} flexWrap={'wrap'} style={{ gap: 8 }} />
    </Panel>
  )
}

const Chat = ({ ...props }) => {
  const { messages, sendMessage } = useRoomContext()
  const [message, setMessage] = useState<string>('')
  const chatContainer = useRef<HTMLDivElement>()
  const input = useRef<HTMLInputElement>()

  const send = () => {
    sendMessage(message)
    setMessage('')
    input.current?.focus()
  }

  const handleKeyPress = (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (event.key === 'Enter') send()
  }

  useEffect(() => {
    chatContainer.current?.scrollTo({
      top: chatContainer.current?.scrollHeight,
    })
  }, [messages])

  return (
    <Panel title="Chat" {...props}>
      <VStack flex={1} overflowY={'scroll'} ref={chatContainer}>
        {messages.map((message) => (
          <ChatMessage key={message.timestamp} message={message} />
        ))}
      </VStack>
      <HStack space={4}>
        <Input
          ref={input}
          autoFocus
          flex={1}
          value={message}
          onChangeText={setMessage}
          onKeyPress={handleKeyPress}
        />
        <Button onPress={() => send()}>Send</Button>
      </HStack>
    </Panel>
  )
}

const ChatMessage = ({ message }) => {
  const messageTime = useMemo(() => {
    const date = new Date(message.timestamp)
    const now = new Date()

    const isToday =
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()

    const options = isToday
      ? { hour: 'numeric', minute: 'numeric', hour12: true }
      : {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        }

    return date.toLocaleTimeString('en-US', options)
  }, [message])

  return (
    <VStack>
      <Text>
        {message.alias} - {messageTime}
      </Text>
      <Text>{message.message} </Text>
    </VStack>
  )
}

export default secureScreen(RoomTestScreen)
