import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'
import Configuration from '../../../../domain/services/ConfigurationService'
import { useFeatures } from '../../providers/FeatureProvider'
import { CustomerIo } from './useCustomerIo'

export const useCustomerIo = (): CustomerIo => {
  const { features } = useFeatures()

  const analytics = AnalyticsBrowser.load({
    writeKey: Configuration.CUSTOMER_IO_JAVASCRIPT_WRITE_KEY,
  })

  const identify = (id: string, userProfile: Record<string, any>) => {
    if (!features.CUSTOMER_IO) return
    if (!id) console.warn('Cannot identify without id')

    analytics
      .identify(id, {
        id: userProfile.id,
        email: userProfile.email,
        created_at: userProfile.createdOn,
        organization: userProfile.organization,
        professionalRoles: userProfile.professionalRoles,
      })
      .catch((error) => {
        console.error('Customer.io identify error:', error)
      })
  }

  const track = (name: string, properties: Record<string, any>) => {
    if (!features.CUSTOMER_IO) return
    if (!name) console.warn('Cannot †rack without name')

    analytics.track(name, properties).catch((error) => {
      console.error('Customer.io track error:', error)
    })
  }

  return {
    identify,
    track,
  }
}

export default useCustomerIo
