import { Button, Flex, Image, Text, View, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'
import Sheet from '../../_shared/card/Sheet'
import FaIcon from '../../_shared/FaIcon'
import StartRoom from './StartRoom'

const Live = require('../../../assets/images/live-groups/modals/live-moderator.png')

type LiveGroupModalProps = {
  show: boolean
  closeModal?: () => void
  heading: string
  body: string
  groupInactive?: boolean
}

const LiveGroupModal = ({
  show,
  closeModal,
  heading,
  body,
  groupInactive,
}: LiveGroupModalProps) => {
  const { height, width } = useWindowDimensions()

  return (
    <View>
      <Modal
        animationIn={'slideInUp'}
        animationOut="slideOutDown"
        isVisible={show}
        deviceHeight={height}
        deviceWidth={width}
        onBackdropPress={closeModal}>
        <Sheet
          props={{
            w: '320px',
            alignSelf: 'center',
          }}>
          <VStack h="100%">
            {closeModal && (
              <Button
                variant="ghost"
                borderRadius="full"
                ml="auto"
                onPress={closeModal}
                position="absolute"
                right={0}
                top={0}
                zIndex={1}>
                <FaIcon name="xmark" size={20} />
              </Button>
            )}
            <Flex alignItems="center" py={3}>
              <Image
                source={Live}
                alt="smiling crab"
                width={160}
                height={120}
              />
              <Text
                mt="16px"
                mb="8px"
                fontSize="18px"
                color="primary.900"
                fontWeight={700}>
                {heading}
              </Text>
              <Text mb="28px" color="primary.900">
                {body}
              </Text>
              {groupInactive && <StartRoom />}
            </Flex>
          </VStack>
        </Sheet>
      </Modal>
    </View>
  )
}

export default LiveGroupModal
