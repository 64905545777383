import ClevertapAdapter from '../../../domain/services/clevertap/ClevertapAdapter'
import useCustomerIo from './customerio/useCustomerIo'
import useMixpanel from './mixpanel/useMixpanel'

const useAnalytics = () => {
  const customerIo = useCustomerIo()
  const mixpanel = useMixpanel()

  const track = (eventName: string, eventData: Record<string, any> = {}) => {
    mixpanel.track(eventName, { ...eventData })
    ClevertapAdapter.pushEvent(eventName, { ...eventData })
    customerIo.track(eventName, { ...eventData })
  }

  return {
    track,
  }
}

export default useAnalytics
