import { Box, Button, Flex, HStack, Text } from 'native-base'
import { useState } from 'react'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { useRoomContext } from '../../../providers/Room30Provider'
import RoomAction from '../../room/RoomAction'
import TechSupport from '../../room/TechSupport'
import FaIcon from '../../_shared/FaIcon'
import RoomSettings from './RoomSettings'

const RoomFooter = ({ isMobile = false }) => {
  const {
    microphoneOff,
    microphoneOn,
    cameraOn,
    cameraOff,
    screenShareOff,
    screenShareOn,
    handDown,
    handUp,
    leave,
    stop,
    me,
    setShowChat,
    setShowParticipants,
    showChat,
    showParticipants,
  } = useRoomContext()

  const [settingsOpen, setSettingsOpen] = useState(false)

  const actions = [
    {
      handlePress: () => (me?.microphone ? microphoneOff() : microphoneOn()),
      isActive: me?.microphone,
      isVisible: me?.role !== AttendeeRole.Spectator,
      isMain: true,
      icon: 'microphone-slash',
      activeIcon: 'microphone',
      isSolid: true,
    },
    {
      handlePress: () => (me?.camera ? cameraOff() : cameraOn()),
      isActive: me?.camera,
      isVisible: me?.role === AttendeeRole.Moderator,
      isMain: true,
      icon: 'video-slash',
      activeIcon: 'video',
      moderatorOnly: true,
    },
    {
      handlePress: () => (me?.screenShare ? screenShareOff() : screenShareOn()),
      isActive: me?.screenShare,
      isVisible: me?.role === AttendeeRole.Moderator,
      isMain: true,
      icon: 'display-slash',
      activeIcon: 'display',
      bg: 'muted.600',
      activeBg: 'secondary.500',
      activeColor: 'text.50',
    },
    {
      handlePress: () => (me?.handRaised ? handDown() : handUp()),
      isActive: me?.handRaised,
      isVisible:
        me?.role === AttendeeRole.Participant ||
        me?.role === AttendeeRole.Spectator,
      isMain: true,
      icon: 'hand',
      isSolid: true,
      bg: 'muted.600',
      activeBg: 'secondary.500',
      activeColor: 'text.50',
    },
    {
      handlePress: () => leave(),
      isVisible:
        me?.role === AttendeeRole.Participant ||
        me?.role === AttendeeRole.Spectator,
      isMain: true,
      icon: 'arrow-right-from-bracket',
      bg: 'error.500',
    },
    {
      handlePress: () => {
        setShowParticipants(!showParticipants)
      },
      isActive: false,
      isVisible: true,
      icon: 'user-group',
      // badge: 1,
    },
    {
      handlePress: () => {
        setShowChat(!showChat)
      },
      isActive: true,
      isVisible: true,
      icon: 'comments',
      // badge: 1,
    },
    {
      handlePress: () => {
        setSettingsOpen(!settingsOpen)
      },
      isActive: true,
      isVisible: true,
      icon: 'ellipsis-vertical',
    },
  ]

  const centerActions = []
  const rightActions = []

  actions.forEach((a) => {
    // Set position
    if (!a.isVisible) return
    else if (a.isMain) centerActions.push(a)
    else rightActions.push(a)
  })

  return (
    <Box
      position="absolute"
      bottom="0"
      h="64px"
      w="100%"
      bg={isMobile ? 'transparent' : 'muted.800'}
      pt={isMobile ? 0 : '3'}
      pl={isMobile ? 0 : '3'}
      shadow={isMobile ? 0 : '3'}
      safeAreaBottom>
      {me?.role !== AttendeeRole.Moderator && !isMobile && (
        <HStack position="absolute" left="0" pl="3" space="3">
          <Flex flex={1}>
            <TechSupport />
          </Flex>
        </HStack>
      )}

      <HStack ml="auto" mr="auto" space="3">
        {centerActions.map((action, index) => (
          <Flex key={index}>
            <RoomAction action={action} />
          </Flex>
        ))}
        {me.role === AttendeeRole.Moderator && (
          <Button
            backgroundColor={'error.500'}
            onPress={() => stop()}
            borderRadius="full"
            leftIcon={
              <FaIcon
                name={'arrow-right-from-bracket'}
                size={20}
                color={'text.50'}
              />
            }>
            <Text color={'text.50'}>Stop group</Text>
          </Button>
        )}
        {/* {me.role === AttendeeRole.Moderator && (
          <StartRoom />
        )} */}
      </HStack>
      {!isMobile && (
        <HStack position="absolute" right="0" pr="3" space="3">
          {rightActions.map((action, index) => (
            <Flex key={index} flex={1}>
              <RoomAction action={action} />
            </Flex>
          ))}
        </HStack>
      )}

      <RoomSettings
        settingsOpen={settingsOpen}
        closeSettings={() => setSettingsOpen(false)}
      />
    </Box>
  )
}

export default RoomFooter
