import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Camera, NO_CAMERA } from '../../../domain/models/interfaces/camera'
import useStorage from '../../../domain/services/useStorage'
import useCameras, { UseCamerasResult } from '../hooks/useCameras'

export interface UseCamerasContext extends UseCamerasResult {
  selectCamera: (camera: Camera) => void
  selectedCamera: Camera | null
  shouldRequestPermission: boolean
  requestCameraPermission: () => void
}

const CamerasContext = createContext<UseCamerasContext | undefined>(undefined)

export const useCamerasContext = (): UseCamerasContext =>
  useContext(CamerasContext)

interface CamerasProviderProps {
  children: ReactNode
}

export const CamerasProvider: React.FC<CamerasProviderProps> = ({
  children,
}) => {
  const [shouldRequestPermission, setShouldRequestPermission] = useState(false)
  const { cameras, error, refresh } = useCameras(shouldRequestPermission)

  const [savedCamera, setSavedCamera] = useStorage('@camera', NO_CAMERA)
  const [selectedCamera, setSelectedCamera] = useState<Camera>(
    savedCamera.name ?? NO_CAMERA
  )

  const selectCamera = (camera: Camera) => {
    setSelectedCamera(camera)
    setSavedCamera(camera.name)
  }

  const requestCameraPermission = () => {
    setShouldRequestPermission(true)
  }

  useEffect(() => {
    const matchingCamera = cameras.find((camera) => camera.name === savedCamera)
    setSelectedCamera(matchingCamera ?? NO_CAMERA)
  }, [cameras, savedCamera])

  const context: UseCamerasContext = {
    cameras,
    error,
    refresh,
    selectCamera,
    selectedCamera,
    shouldRequestPermission,
    requestCameraPermission,
  }

  return (
    <CamerasContext.Provider value={context}>
      {children}
    </CamerasContext.Provider>
  )
}

export default CamerasProvider
