import { Text, useBreakpointValue } from 'native-base'
import AttendeeRole from '../../../domain/enums/AttendeeRole'
import { AttendeeState30 } from '../../../domain/enums/AttendeeState'
import RoomCompleted from '../components/liveGroup/feedback/RoomCompleted'
import RoomFeedback from '../components/liveGroup/feedback/RoomFeedback'
import Orientation from '../components/liveGroup/orientation/Orientation'
import ParticipantLobby from '../components/liveGroup/ParticipantLobby'
import secureScreen from '../hooks/authentication/secureScreen'
import { CamerasProvider } from '../providers/CameraProvider'
import { MicrophonesProvider } from '../providers/MicrophoneProvider'
import { RoomProvider, useRoomContext } from '../providers/Room30Provider'
import RoomScreen30 from './liveGroup/RoomScreen30'
import LoadingScreen from './LoadingScreen'

const RenderScreen = () => {
  const { error, me } = useRoomContext()

  if (error) return <Text>Error - {error}</Text>

  const breakpoint = useBreakpointValue({
    base: 'mobile',
    lg: 'browser',
  })

  switch (me?.screen) {
    case AttendeeState30.Orientation:
      return <Orientation />
    case AttendeeState30.Lobby:
      if (me.role !== AttendeeRole.Moderator) {
        return <ParticipantLobby />
      } else {
        return <RoomScreen30 isLobbyRoom={true} />
      }
    case AttendeeState30.Feedback:
      return <RoomFeedback />
    case AttendeeState30.Room:
      return <RoomScreen30 isLobbyRoom={false} />
    case AttendeeState30.Complete:
      return <RoomCompleted />
    default:
      return <LoadingScreen />
  }
}

const RoomTestScreenWithUI = ({ route }) => {
  const roomId = route.params?.roomId

  return (
    <MicrophonesProvider>
      <CamerasProvider>
        <RoomProvider roomId={roomId}>
          <RenderScreen />
        </RoomProvider>
      </CamerasProvider>
    </MicrophonesProvider>
  )
}

export default secureScreen(RoomTestScreenWithUI)
