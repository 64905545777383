import { HStack, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useRoomContext } from '../../../providers/Room30Provider'
import TechSupport from '../../room/TechSupport'

const dateFormat = new Intl.DateTimeFormat([], {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
})

const timeFormat = new Intl.DateTimeFormat([], {
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
})

const InfoTab = () => {
  const { info } = useRoomContext()
  const [startsAt, setStartsAt] = useState('')

  useEffect(() => {
    const startsAtDate = new Date(info.startTime)
    const date = dateFormat.format(startsAtDate)
    const time = timeFormat.format(startsAtDate)

    setStartsAt(`${date} • ${time}`)
  }, [info.startTime])

  return (
    <VStack p={4}>
      <Text color="black" fontWeight={700}>
        {info.title}
      </Text>
      <Text mt={2} mb={4} color="black">
        {startsAt}
      </Text>
      {info.description && (
        <VStack mb={10}>
          <Text mb={2} color="text.500" fontWeight={500}>
            Description
          </Text>
          <Text color="black" mb={10}>
            {info.description}
          </Text>
        </VStack>
      )}
      <HStack
        flexDir="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center">
        <Text mb={2} fontSize="16px" color="muted.500">
          Experiencing technical issues?
        </Text>
        <TechSupport isMobile />
      </HStack>
    </VStack>
  )
}

export default InfoTab
