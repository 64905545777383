import mixpanel from 'mixpanel-browser'
import Configuration from '../../../../domain/services/ConfigurationService'
import { useFeatures } from '../../providers/FeatureProvider'

const useMixpanel: UseMixpanel = () => {
  const { features } = useFeatures()

  const isFeatureEnabled = () => features.CUSTOMER_IO === true

  const init = () => {
    if (isFeatureEnabled()) return

    console.debug('Mixpanel init')
    mixpanel.init(Configuration.MIXPANEL_TOKEN, { debug: true })
  }

  const identify = (data) => {
    if (isFeatureEnabled()) return

    console.debug('Mixpanel identify', data)
    mixpanel.identify(data)
  }

  const track = (data, params) => {
    if (isFeatureEnabled()) return

    console.debug('Mixpanel track', data, params)
    mixpanel.track(data, params)
  }

  return { init, identify, track }
}

export default useMixpanel
