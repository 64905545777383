import { Button, HStack, Text, View, VStack } from 'native-base'
import { useCallback, useState } from 'react'
import { ActivityIndicator, Linking, Share } from 'react-native'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import useAnalytics from '../../hooks/useAnalytics'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import ProgressModal from '../_shared/ProgressModal'
import CopyIcon from './CopyIcon'
import EmailIcon from './EmailIcon'
import MessageIcon from './MessageIcon'
import ShareIcon from './ShareIcon'

const CounterReferrals = () => {
  const { userProfile, isFetched } = useUserProfileContext()
  const isMobile = useMobileBreakpoint()
  const analytics = useAnalytics()
  const referralUrl = `https://www.mycabana.health/referral?referralCode=${userProfile?.referralCode}`
  const subject = 'Try out Cabana'
  const message = `Hi! Checkout Cabana, a new mental wellness benefit.  It's free to use and private.  I have been using it and I think you'd like it, too. ${referralUrl}`

  const shareAnalytics = (type) => {
    analytics.track('Referrals Shared', {
      type,
    })
  }

  const [copyLinkNotification, setCopyLinkNotification] = useState(false)

  const shareContent = {
    message,
  }

  const shareOptions = {
    subject,
  }

  const urlSMS = `sms:&body=${message}`

  const handleShare = async () => {
    try {
      const response = await Share.share(shareContent, shareOptions)
      if (response.action === Share.sharedAction) {
        shareAnalytics('iOS')
      }
    } catch (err) {
      console.debug(err)
    }
  }

  const handleSendMessage = useCallback(async () => {
    const encoded = encodeURI(urlSMS)
    const supported = await Linking.canOpenURL(encoded)
    if (supported) {
      shareAnalytics('message')
      await Linking.openURL(encoded)
    } else {
      console.debug('Cannot open message')
    }
  }, [urlSMS])

  const handleSendEmail = useCallback(async () => {
    const encodedSubject = encodeURIComponent(subject)
    const encodedBody = encodeURIComponent(message)
    const encoded = `mailto:?subject=${encodedSubject}&body=${encodedBody}`
    const supported = await Linking.canOpenURL(encoded)
    if (supported) {
      shareAnalytics('email')
      await Linking.openURL(encoded)
    } else {
      console.debug('Cannot open email')
    }
  }, [subject, message])

  const copyToClipboard = async () => {
    shareAnalytics('copy link')
    await navigator.clipboard.writeText(referralUrl)
    setTimeout(() => {
      setCopyLinkNotification(true)
    }, 400)
  }

  return (
    <VStack
      borderRadius="20px"
      py={5}
      px={4}
      mx={5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      <Text color="primary.900" fontSize="18px" fontWeight={700}>
        Share Cabana
      </Text>
      <HStack justifyContent="center" alignItems="center" my={-4}>
        {!isFetched ? (
          // Change for loading state component
          <ActivityIndicator size="large" color="secondary.500" />
        ) : (
          <>
            <Text fontSize={110} fontWeight={600} color="secondary.500">
              {userProfile.referralCount}
            </Text>
            <Text
              fontSize={24}
              fontWeight={600}
              color="primary.900"
              lineHeight={28}
              marginTop={6}
              marginLeft={4}>
              Completed{'\n'}invites
            </Text>
          </>
        )}
      </HStack>
      <Text color="primary.900" px={3} alignSelf="center" textAlign="center">
        Recommend Cabana to a coworker and{'\n'}earn rewards once they’ve signed
        up!
      </Text>
      <HStack
        justifyContent={isMobile ? 'space-between' : 'center'}
        alignSelf="center"
        width="80%"
        mt={6}>
        {isMobile && (
          <View display="flex" flexDirection="column" alignItems="center">
            <MessageIcon onPress={handleSendMessage} />
            <Text color="primary.900" fontWeight={500} mt={2}>
              Message
            </Text>
          </View>
        )}

        <View display="flex" flexDirection="column" alignItems="center">
          <Button p={0} variant="unstyled" onPress={handleSendEmail}>
            <EmailIcon />
          </Button>
          <Text color="primary.900" fontWeight={500} mt={2}>
            Email
          </Text>
        </View>
        {!isMobile && (
          <View
            display="flex"
            flexDirection="column"
            alignItems="center"
            ml={7}>
            <Button p={0} variant="unstyled" onPress={copyToClipboard}>
              <CopyIcon />
            </Button>
            <Text color="primary.900" fontWeight={500} mt={2}>
              Copy link
            </Text>
          </View>
        )}
        {isMobile && (
          <View display="flex" flexDirection="column" alignItems="center">
            <ShareIcon onPress={handleShare} />
            <Text color="primary.900" fontWeight={500} mt={2}>
              Share
            </Text>
          </View>
        )}
        <ProgressModal
          downloadProgress={100}
          isVisible={copyLinkNotification}
          successMessage={<Text>Link copied!</Text>}
          animationDuration={1000}
          position={{
            top: 10,
            right: 0,
          }}
          callback={() => {
            setTimeout(() => {
              setCopyLinkNotification(false)
            }, 500)
          }}
          progressMessage={undefined}
        />
      </HStack>
    </VStack>
  )
}

export default CounterReferrals
