const AttendeeState = {
  NotJoined: 'NOT_JOINED',
  Orientation: 'ORIENTATION',
  Waiting: 'WAITING',
  Joined: 'JOINED',
  Left: 'LEFT',
  FeedbackRequested: 'FEEDBACK_REQUESTED',
  FeedbackSubmitted: 'FEEDBACK_SUBMITTED',
  Complete: 'COMPLETE',
}

export const AttendeeState30 = {
  Orientation: 'ORIENTATION',
  Lobby: 'LOBBY',
  Feedback: 'FEEDBACK',
  Room: 'ROOM',
  Complete: 'COMPLETE',
}

export default AttendeeState
