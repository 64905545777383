import { DateTime } from 'luxon'
import { Box, Flex, HStack, Image, Text, VStack } from 'native-base'
import Hyperlink from 'react-native-hyperlink'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useLinkHandler from '../../../hooks/useLinkHandler'
import { useRoomContext } from '../../../providers/Room30Provider'
import { theme } from '../../../theme/theme'
import Orb from '../../room/Orb'
import Caption from '../../_shared/text/Caption'

const RenderMessages = ({ messages }) => {
  const isDesktop = useDesktopBreakpoint()
  const { participants, me } = useRoomContext()

  const othersHyperLinkColor = theme.colors.secondary[600]
  const selfHyperLinkColor = theme.colors.white

  const isEmojiOnly = (messageText: string) => {
    return /^[\p{Extended_Pictographic}❤️]+$/u.test(messageText)
  }

  const getAttendee = (message) => {
    return participants.find((attendee) => attendee.alias === message.alias)
  }

  const fromMe = (message) => {
    return message.alias === me?.alias
  }

  const hasName = (index) => {
    const user = messages[index]?.alias
    const prevUser = messages[index - 1]?.alias
    return user !== prevUser
  }

  const hasOrb = (index) => {
    const user = messages[index]?.alias
    const nextUser = messages[index + 1]?.alias
    return user !== nextUser && !fromMe(messages[index])
  }

  let lastTimeLabelDt = null // Dt = luxon.DateTime

  return (
    <VStack mb="4" ml="16px" mr={isDesktop ? '4px' : '16px'}>
      {messages.map((message, index) => {
        // Add time label if 5 minutes since last one
        const newDt = DateTime.fromISO(message.timestamp)

        if (!lastTimeLabelDt || newDt > lastTimeLabelDt.plus({ minutes: 5 })) {
          message.timeLabel = newDt.toLocaleString(DateTime.TIME_SIMPLE)
          lastTimeLabelDt = newDt
        }

        // Style message
        if (message.alias === 'SYSTEM') {
          return (
            <Box key={index} mt="2">
              <Caption textAlign="center">
                {message.message} at{' '}
                {DateTime.fromISO(message.timestamp).toLocaleString(
                  DateTime.TIME_SIMPLE
                )}
              </Caption>
            </Box>
          )
        } else {
          return (
            <VStack key={index} mt="6px">
              {message.timeLabel && (
                <Box mb="6px">
                  <Caption textAlign="center">{message.timeLabel}</Caption>
                </Box>
              )}
              <HStack
                justifyContent={fromMe(message) ? 'flex-end' : 'flex-start'}>
                <Flex mt="auto" mr="2" opacity={hasOrb(index) ? '1' : '0'}>
                  {getAttendee(message)?.photo ? (
                    <Image
                      src={getAttendee(message).photo}
                      alt="Alternate Text"
                      size="16px"
                      borderRadius="full"
                    />
                  ) : (
                    <Orb
                      attendee={getAttendee(message)}
                      participantColor={getAttendee(message).orbColor}
                    />
                  )}
                </Flex>
                <VStack>
                  {hasName(index) && !fromMe(message) && (
                    <Caption>
                      {getAttendee(message)?.alias ?? 'Someone'}
                    </Caption>
                  )}
                  {isEmojiOnly(message.message) ? (
                    <Text fontSize="2xl">{message.message}</Text>
                  ) : (
                    <Box
                      w="100%"
                      maxW="241px"
                      borderRadius="xl"
                      bg={fromMe(message) ? 'secondary.500' : 'muted.200'}
                      p={2}>
                      <Hyperlink
                        onPress={(url) => useLinkHandler(url)}
                        linkStyle={{
                          color: fromMe(message)
                            ? selfHyperLinkColor
                            : othersHyperLinkColor,
                          textDecorationLine: 'underline',
                        }}>
                        <Text
                          color={fromMe(message) ? 'muted.50' : 'muted.900'}>
                          {message.message}
                        </Text>
                      </Hyperlink>
                    </Box>
                  )}
                </VStack>
              </HStack>
            </VStack>
          )
        }
      })}
    </VStack>
  )
}

export default RenderMessages
