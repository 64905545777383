import { Alert, Button, HStack, Input, Text, View } from 'native-base'
import { useState } from 'react'
import { useRoomContext } from '../../../providers/Room30Provider'
import Orb from '../../room/Orb'

const AliasEntry = () => {
  const { chooseAlias, me } = useRoomContext()
  const [inputValue, setInputValue] = useState(me.alias || '')
  const [aliasError, setAliasError] = useState('')

  const handleInputChange = (text) => {
    setInputValue(text)
  }

  const handleSave = async () => {
    try {
      if (inputValue) {
        await chooseAlias(inputValue)
      }
    } catch (error) {
      setAliasError('Alias in use')
    }
  }

  return (
    <View>
      {aliasError !== '' && (
        <>
          <Alert colorScheme="error" variant="subtle">
            <Text w="100%" color="text.900">
              {aliasError}
            </Text>
          </Alert>
        </>
      )}
      <HStack mt="1" mb="5">
        <Input
          size="lg"
          flex={1}
          value={inputValue}
          onChangeText={handleInputChange}
        />
        <Button
          colorScheme="primary"
          ml="3"
          onPress={handleSave}
          isDisabled={!inputValue}>
          Save
        </Button>
      </HStack>
      <Text>This is what you'll look like in the group</Text>
      <HStack mt="16px" alignItems="center">
        <Orb
          attendee={{
            alias: inputValue || me.alias,
          }}
          participantColor={me.orbColor}
        />
        <Text color="primary.900" ml="10px" fontSize="16px" fontWeight="500">
          {inputValue || me.alias}
        </Text>
      </HStack>
    </View>
  )
}

export default AliasEntry
