import { Box, Center, HStack, Switch, VStack } from 'native-base'
import { useRoomContext } from '../../providers/Room30Provider'
import FaIcon from '../_shared/FaIcon'
import VideoFeed from './VideoFeed'

const VideoPreview = () => {
  const { me, microphoneOn, microphoneOff, cameraOff, cameraOn, video } =
    useRoomContext()

  return (
    <VStack>
      <Box bg="black" borderRadius="6px" overflow="hidden" mt="3">
        <Center>
          <VideoFeed height="360px" borderRadius="6" />
        </Center>
      </Box>
      <HStack p="3" space="3">
        <HStack alignItems="center">
          <Switch
            isChecked={me?.microphone}
            onValueChange={() => {
              me?.microphone ? microphoneOff() : microphoneOn()
            }}
            colorScheme="secondary"
            mr="1"
          />
          <FaIcon name="microphone" size={16} isSolid props={{ ml: '1' }} />
        </HStack>
        <HStack alignItems="center">
          <Switch
            isChecked={me?.camera}
            onValueChange={() => {
              me?.camera ? cameraOff() : cameraOn()
            }}
            colorScheme="secondary"
            mr="1"
          />
          <FaIcon name="video" size={16} props={{ ml: '1' }} />
        </HStack>
      </HStack>
    </VStack>
  )
}

export default VideoPreview
