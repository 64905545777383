import { Box } from 'native-base'
import { useEffect, useRef, useState } from 'react'
import { useWindowDimensions } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useRoomContext } from '../../../providers/Room30Provider'
import Card from '../../_shared/card/Card'
import Caption from '../../_shared/text/Caption'
import ChatInput from './ChatInput'
import RenderMessages from './RenderMessages'

const Chat = () => {
  const { width } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()
  const { messages, sendMessage, participants, me, setShowChat } =
    useRoomContext()

  const input = useRef(null)
  const scrollView = useRef(null)
  const inputCursorRef = useRef(0)

  const [typingMessage, setTypingMessage] = useState('')
  const [isUserTyping, setIsUserTyping] = useState(false)

  const emojis = [
    { hex: '👍', name: 'Thumbs up' },
    { hex: '❤️', name: 'Heart' },
    { hex: '👏', name: 'Clapping' },
    { hex: '🤔', name: 'Inquisitive' },
    { hex: '😂', name: 'Laughing' },
    { hex: '🎉', name: 'Party popper' },
  ]

  const onSelectionChange = (cursorPosition) => {
    const newSelection = cursorPosition.nativeEvent.selection
    if (newSelection.start === newSelection.end) {
      inputCursorRef.current = newSelection.start
    }
  }

  useEffect(() => {
    let typingCount = 0

    for (const user of participants) {
      if (user.typing && user.id !== me.id) {
        typingCount++

        if (user.role === AttendeeRole.Moderator && typingCount === 1) {
          setIsUserTyping(true)
          setTypingMessage('Moderator is typing...')
        }
        if (
          (user.role === AttendeeRole.Participant ||
            user.role === AttendeeRole.Spectator) &&
          typingCount === 1
        ) {
          setIsUserTyping(true)
          setTypingMessage(`${user.alias} is typing...`)
        }
      }

      if (typingCount >= 2) {
        setIsUserTyping(true)
        setTypingMessage('Participants are typing...')
        break
      }

      if (typingCount === 0) {
        setIsUserTyping(false)
        setTypingMessage('')
      }
    }
  }, [participants, me.id])

  return (
    <Card
      title={isDesktop && 'Chat'}
      boxProps={{
        w: !isDesktop ? width : '320px',
        h: '100%',
        borderWidth: !isDesktop ? '0' : '1px',
      }}
      handleClose={() => setShowChat(false)}
      scrollViewProps={{
        ref: scrollView,
        onContentSizeChange: () => scrollView.current?.scrollToEnd(),
      }}
      hasPadding={{ body: false, title: isDesktop, actions: true }}
      bottomMessage={isUserTyping ? typingMessage : ''}
      body={<RenderMessages messages={messages} />}
      actions={
        <Box width="100%">
          <Caption>To everyone</Caption>
          <ChatInput
            sendMessage={sendMessage}
            emojis={emojis}
            inputRef={input}
            onSelectionChange={onSelectionChange}
          />
        </Box>
      }
    />
  )
}

export default Chat
