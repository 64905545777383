import { Heading, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'

const LiveGroupHeading = ({
  textAlign = 'center',
  hasWelcome = true,
  info,
}) => {
  const [startsAt, setStartsAt] = useState('')
  const dateFormat = new Intl.DateTimeFormat([], {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
  })
  const timeFormat = new Intl.DateTimeFormat([], {
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  })

  useEffect(() => {
    const startsAtDate = new Date(info.startTime)
    const date = dateFormat.format(startsAtDate)
    const time = timeFormat.format(startsAtDate)

    setStartsAt(`${date} • ${time}`)
  }, [info.startTime])

  return (
    <VStack mb={5}>
      {hasWelcome && (
        <Text fontSize="xl" color="#000000" textAlign={textAlign}>
          Welcome to
        </Text>
      )}
      <Heading mb="1" color="#000000" textAlign={textAlign}>
        {info.title}
      </Heading>
      <Text fontSize="md" color="#000000" textAlign={textAlign}>
        {startsAt}
      </Text>
    </VStack>
  )
}

export default LiveGroupHeading
