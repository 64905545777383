import { Alert, Avatar, HStack, Spacer, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { useRoomContext } from '../../../providers/Room30Provider'
import FaIcon from '../../_shared/FaIcon'

const ScreenShareAlert = () => {
  const { me, participants, screenShareOff } = useRoomContext()
  const [presenter, setPresenter] = useState(null)

  useEffect(() => {
    setPresenter(participants.find((p) => p.screenShare))
  }, [participants])

  return (
    <>
      {presenter && (
        <Alert bg="muted.600" opacity="0.9" borderRadius="md" mb="2">
          <HStack w="100%">
            {me.screenShare ? (
              <FaIcon name="display" size={20} color="text.50" />
            ) : (
              <Avatar size="20px" source={presenter.photo} />
            )}
            <Text color="text.50" ml="2.5">
              {me.screenShare
                ? "You're presenting to everyone"
                : `${presenter.alias ?? 'Moderator'} is now presenting`}
            </Text>
            <Spacer />
            {me.screenShare && (
              <Text color="secondary.100" onPress={screenShareOff}>
                Stop presenting
              </Text>
            )}
          </HStack>
        </Alert>
      )}
    </>
  )
}

export default ScreenShareAlert
