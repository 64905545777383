import {
  Badge,
  Box,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
} from 'native-base'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import { AttendeeState30 } from '../../../../../domain/enums/AttendeeState'
import { useRoomContext } from '../../../providers/Room30Provider'
import Orb from '../../room/Orb'
import FaIcon from '../../_shared/FaIcon'

const RosterItem = ({ attendee }) => {
  const { me, microphoneOn, microphoneOff, attendeeMicrophoneOff } =
    useRoomContext()

  let aliasInfo
  if (attendee.id === me.id) aliasInfo = '(You)'
  else if (attendee.role === AttendeeRole.Moderator) aliasInfo = '(Moderator)'

  let deviceIcon
  switch (attendee.deviceType) {
    case 'BROWSER':
      deviceIcon = 'web'
      break
    case 'MOBILE':
      deviceIcon = 'cellphone'
      break
    case 'DIAL-IN':
      deviceIcon = 'phone-outline'
      break
  }

  let handlePressMic = () => {}
  if (me.role === AttendeeRole.Moderator && attendee.microphone)
    handlePressMic = () => {
      attendeeMicrophoneOff(attendee.id)
    }
  else if (attendee.id === me.id)
    handlePressMic = () => {
      me?.microphone ? microphoneOff() : microphoneOn()
    }

  let rightHandContent
  if (
    attendee.role === AttendeeRole.Participant &&
    attendee.state === AttendeeState30.Lobby
  ) {
    rightHandContent = (
      <Badge bg="muted.500" borderRadius="full">
        <Text fontSize="xs" color="text.50">
          waiting to join
        </Text>
      </Badge>
    )
    // } else if (
    //   attendee.role === AttendeeRole.Moderator
    //   // attendee.state === AttendeeState.NotJoined
    // ) {
    //   rightHandContent = (
    //     <Badge bg="muted.500" borderRadius="full">
    //       <Text fontSize="xs" color="text.50">
    //         not joined yet
    //       </Text>
    //     </Badge>
    //   )
    //
  } else if (attendee.screen === AttendeeState30.Room) {
    rightHandContent = (
      <>
        {attendee.handRaised && (
          <FaIcon name="hand" size={20} color="yellow.500" isSolid />
        )}
        {attendee.role === AttendeeRole.Spectator ? (
          <Text fontSize="12px" color="muted.500">
            Chat Only
          </Text>
        ) : (
          <IconButton
            icon={
              <FaIcon
                name={attendee.microphone ? 'microphone' : 'microphone-slash'}
                size={20}
                color={
                  attendee.microphone && attendee.speaking
                    ? 'success.500'
                    : 'text.400'
                }
                isSolid
              />
            }
            borderRadius="full"
            p="1"
            isHovered={attendee.microphone && attendee.speaking}
            colorScheme={
              attendee.microphone && attendee.speaking ? 'success' : ''
            }
            onPress={handlePressMic}
          />
        )}
      </>
    )
  }

  return (
    <Box>
      <HStack alignItems="center" justifyContent="space-between">
        {attendee.photo ? (
          <Image
            src={attendee.photo}
            alt="Alternate Text"
            size="16px"
            borderRadius="full"
          />
        ) : (
          <Orb attendee={attendee} participantColor={attendee.orbColor} />
        )}
        <Text color="primary.900" ml="10px" fontSize="16px" fontWeight="500">
          {attendee.alias} {aliasInfo}
        </Text>
        <Spacer />
        {rightHandContent}
      </HStack>
    </Box>
  )
}

export default RosterItem
