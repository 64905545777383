import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, Flex, Heading, Image, Text, VStack } from 'native-base'
import { useRef, useState } from 'react'
import { Linking, StyleSheet, Text as TextNative } from 'react-native'

import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import useAuthentication from '../../hooks/authentication/useAuthentication'
import useAnalytics from '../../hooks/useAnalytics'
import useIntercom from '../../hooks/useIntercom'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import { NavigationParams } from '../../screens/NavigationScreen'
import { theme } from '../../theme/theme'
import BackButton from '../navigation/BackButton'
import FormAccessoryNavigation from '../_shared/form/FormAccessoryNavigation'
import FormInput from '../_shared/form/FormInput'

const logo = require('../../assets/images/logos/cabana-horizontal.png')

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Poppins_600SemiBold',
  },
})
interface AccessCodeFormProps {
  code?: string
  codeEmailed?: boolean
}

const SuccessMessage = () => (
  <Flex direction="column" justify="space-between" align="center" flex={1}>
    <Flex flex={1} justify="center" align="center">
      <Image source={logo} width="314px" height="82px" />
    </Flex>
    <Flex flex={1} justify="center" align="center">
      <Text fontSize="40px" mb="3" color="primary.800" fontWeight={500}>
        Success!
      </Text>
      <Text fontSize="20px" color="text.800">
        Your account is now active.
      </Text>
    </Flex>
    <Flex flex={1} />
  </Flex>
)

const AccessCodeForm = ({ code, codeEmailed }: AccessCodeFormProps) => {
  const { AuthPasswordlessApi, MemberProfileApi } = useCabanaApi()
  const { user } = useAuthentication()
  const { openChat } = useIntercom()
  const navigation =
    useNavigation<NativeStackNavigationProp<NavigationParams>>()
  const isMobile = useMobileBreakpoint()
  const inputRefs = [useRef(), useRef(), useRef()]
  const focusedInput = useRef(0)
  const email = user?.email
  const [error, setError] = useState(<></>)
  const [organizationName, setOrganizationName] = useState('')
  const [accessCode, setAccessCode] = useState(code ?? '')
  const [isValid, setIsValid] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const analytics = useAnalytics()

  const verifyEmail = () => {
    setEmailSent(true)
    AuthPasswordlessApi.requestAccessCode(email)
      .then(() => {
        analytics.track('Access Code Requested', {
          Email: email,
        })
        setEmailSent(false)
      })
      .catch((e) => {
        console.debug(e)
        setEmailSent(false)
      })
  }

  const verifyAccessCode = () => {
    MemberProfileApi.accessCode(accessCode)
      .then(() => {
        setShowSuccess(true)
        setTimeout(() => {
          setShowSuccess(false)
          navigation.navigate('Personalization')
        }, 3000)
      })
      .catch((err) => console.error(err))
  }

  const accessCodeValidation = () =>
    AuthPasswordlessApi.lookupOrganization(accessCode)
      .then((response) => {
        setOrganizationName(response.data.organization)
        setError(<></>)
      })
      .catch((error) => {
        console.debug(error)
        setError(
          <Box mb={5} p={4} bgColor="error.200">
            <Text color="text.900">
              Double check your access code or contact&nbsp;
              <Text
                onPress={() =>
                  Linking.openURL('mailto:support@mycabana.health')
                    .then((data) => console.debug(data))
                    .catch((data) => console.debug(data))
                }
                color={theme.colors.secondary[600]}
                underline>
                support@mycabana.health
              </Text>
            </Text>
          </Box>
        )
        setOrganizationName('')
        return Promise.reject(error)
      })

  return (
    <>
      {showSuccess ? (
        <SuccessMessage />
      ) : (
        <VStack h="100%" w="100%" space={2}>
          {isMobile && <BackButton customText="" />}
          <Heading fontSize={'2xl'} mt="3" color="primary.900">
            Enter your access code
          </Heading>
          <Text color="primary.900" fontSize="16px" my={4}>
            {codeEmailed ? (
              <>
                <Text>We've sent your six-digit access code to</Text>
                <TextNative style={styles.bold}>{` ${email} `}</TextNative>
              </>
            ) : (
              <Text>
                Please enter the six-digit access code provided to you by your
                organization or sponsor.
              </Text>
            )}
          </Text>
          <VStack w="100%" justifyContent="flex-end">
            <FormInput
              placeholder="Access code"
              bg="white"
              keyboardType="number-pad"
              onChangeText={setAccessCode}
              validation={accessCodeValidation}
              onChangeValid={(valid) => setIsValid(valid)}
              defaultValue={accessCode}
              inputAccessoryViewID={'form'}
              inputParamRef={inputRefs[2]}
              onFocusCallback={() => {
                focusedInput.current = 2
              }}
            />
            {organizationName !== '' && (
              <Flex flexDirection="row" mb={4} mt={-4}>
                <Text fontWeight={400} color="text.700">
                  Organization:{' '}
                </Text>
                <Text fontWeight={600} color="primary.700">
                  {organizationName}
                </Text>
              </Flex>
            )}
            {error}
            <Button onPress={verifyAccessCode} isDisabled={!isValid}>
              Confirm organization
            </Button>
          </VStack>
          {codeEmailed && (
            <>
              <Text color="muted.900" fontSize="16px" mt={6} mb={4}>
                Having trouble finding the email? Check your spam folder, or
                re-send the email.
              </Text>
              <Button
                variant="subtle"
                onPress={verifyEmail}
                isLoading={emailSent}>
                Re-send email
              </Button>
            </>
          )}
          <Button variant="unstyled" onPress={openChat}>
            <Text color="primary.700" fontSize="16px">
              Contact Tech Support
            </Text>
          </Button>
        </VStack>
      )}
      <FormAccessoryNavigation
        inputRefs={inputRefs}
        focusedInput={focusedInput}
      />
    </>
  )
}

export default AccessCodeForm
